.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #F7E6F7;
  padding: 4.5em 2.5em;

  .footer__headline {
    padding-bottom: 1.8rem;
    font-size: 1.2em;
    color: #9D51FC;

    @media (min-width: 500px) {
      font-size: 1.2em;
    }

    @media (min-width: 900px) {
      font-size: 1.5em;
      // padding-top: 2rem;
    }
  }

  .footer__icons-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.1em;
    z-index: 1;
    position: relative;

    @media (min-width: 500px) {
      min-width: 20vw;
      max-width: 400px;
      font-size: 1.4em;
    }
  }

  a {
    text-decoration: none;
    color: #9D51FC;
    border-radius: 2em;
    padding: 0.4em;
  }

  & a:hover {
    background: linear-gradient(to top, #c471f5 0%, #fa71cd 100%);
    color: #F7E6F7;
    background-position: 100% 0;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }

  .footer__copyright {
    font-size: 0.7em;
    padding: 4em 0 2em 0;
    color: #C89CFF;

    @media (min-width: 500px) {
      font-size: 1em;
    }
  }
}
