*,
*::before,
*::after {
  box-sizing: border-box;
}

html body {
  height: 100%;
}

@font-face {
  font-family: "gilroy-extrabold";
  src: url("/assets/fonts/gilroy_extrabold_macroman/gilroy-extrabold-webfont.woff2") format("woff2"),
    url("gilroy-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gilroy-light";
  src: url("/assets/fonts/gilroy_light_macroman/gilroy-light-webfont.woff2") format("woff2"),
    url("gilroy-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  background: $backgroundDefault;
  font-family: $bodyFont;
  color: $bodyColor;
}

a {
  &.highlight {
    text-decoration: none;
    border-bottom: 4px solid $fontSecondary;
    box-shadow: inset 0 -8px 0 $fontSecondary;
    color: inherit;
    transition: background 0.1s cubic-bezier(.33, .66, .66, 1);
  }

  &.highlight:hover {
    background: $fontSecondary;
  }

}


.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headerFont;
  font-weight: 700;
}

h1 {
  font-size: 3.625em;
  line-height: 1.22;
  margin: 0.3em 0;
}

h2 {
  font-size: 3em;
  line-height: 1.25;
}

h4 {
  line-height: 1.45em;
  font-size: 1.45em;
}

p {
  line-height: 1.6;
  margin-bottom: 1.5em;
}

.intro {
  display: grid;
  grid-template-columns: [page-start] 1fr [content-start] 45vw [content-end] 35vw [picture-end] 1fr [page-end];
  grid-template-rows: [intro-start] auto [intro-cta] auto [intro-end];
  font-family: $headerFont;
  color: $ctaColor;

  @media (max-width: 1800px) {
    grid-template-columns: [page-start] 1fr [content-start] 45vw [content-end] 35vw [picture-end] 1fr [page-end];
  }
  
  @media (min-width: 2100px) {
    grid-template-columns: [page-start] 1fr [content-start] 45vw [content-end] 35vw [picture-end];
  }

  @media (max-width: 900px) {
    grid-template-columns: [page-start] 1fr [content-start] 80vw [content-end] 1fr [page-end];
  }


  .intro__headline {
    grid-column: content-start / content-end;
    grid-row: intro-start / intro-cta;
    padding: 2em 0 1.5em;
    font-size: 1.5em;
    line-height: 1.1em;

    @media screen and (max-width: 900px) {
      grid-column: content-start / content-end;
    }

    @media (min-width: 550px) {
      font-size: 2em;
    }

    @media (min-width: 720px) {
      font-size: 2.2em;
    }

    @media (min-width: 900px) {
      font-size: 3em;
    }

    @media (min-width: 1010px) {
      font-size: 3.7em;
      padding-top: 2em;
    }
  }

  .intro__image {
    grid-column: content-end / picture-end;
    margin: 2rem;
    align-self: center;

    @media (max-width: 1400px) {
      width: 100%;
    }

    @media screen and (max-width: 900px){
      grid-column: content-start / content-end;
      grid-row: 3/4;
      max-width: 90%;
      margin-left:10%;
    }

  }


  .intro__btn {
    grid-column: page-start / page-end;
    grid-row: intro-cta / intro-end;
    display: flex;
    justify-content: start;
    flex-direction: column;
    z-index: 10;
    position: relative;
    top: -0.8em;
    font-size: 0.8em;

    & a {
      color: $ctaColor;
      text-decoration: none;

      &:hover {
        color: $accentColor;
      }
    }

    @media (min-width: 500px) {
      font-size: 1em;
    }

    @media (min-width: 700px) {
      font-size: 1.2em;
    }

    @media (min-width: 900px) {
      font-size: 1.4em;
    }

    @media (min-width: 1010px) {
      font-size: 1.8em;
    }

    & img {
      padding: 0.4em 0 2em 0;
      width: 2em;
      align-self: center;
    }

    & span {
      color: $accentColor;
      align-self: center;
    }
  }

}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }

  65% {
    transform: translate(0, 15px);
  }

  to {
    transform: translate(0, -0px);
  }
}

.portfolio {
  display: grid;
  grid-template-columns: [page-start] 1fr [content-start] 80vw [content-end] 1fr [page-end];
}

.portfolio-container {
  grid-column: content-start / content-end;
  display: flex;
  flex-direction: column;
  margin: 2em 0 5em 0;
  align-content: center;

  @media (min-width: 500px) {
    margin-top: 3em;
  }

  @media (min-width: 700px) {
    margin-top: 2em;
  }

  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: space-between;
    margin: 10em 0 8em 0;
  }
}

.portfolio-container__start {
  padding: 1em;
}

.start-scroll {
  cursor: pointer !important;
}

.portfolio-container__info {
  display: flex;
  flex: 4;
  flex-direction: column;
  justify-content: center;

  & h2 {
    font-size: 1.8em;
    padding-bottom: 1.125rem;

    @media (min-width: 500px) {
      font-size: 2em;
    }

    @media (min-width: 700px) {
      font-size: 2.4em;
    }

    @media (min-width: 1010px) {
      font-size: 3.3em;
    }
  }

  & .portfolio-container__description {
    font-size: 1.5em;
    font-family: $subheaderFont;
    color: $bodyLight;
    margin-bottom: 1.125rem;
    line-height: 1.7em;

    @media (min-width: 900px) {
      font-size: 1.3em;
      margin-bottom: 1.5em;
      width: 70%;
    }

    @media (min-width: 1160px) {
      // margin-bottom: 2em;
    }
  }


  @media (max-width: 900px) {
    margin-top: 2em;
  }
}

.portfolio-container__info--subheader {
  font-family: $subheaderFont;
  font-size: 1.8em;
  padding-bottom: 0.5em;
}

.portfolio-container__tags-container {
  display: flex;
}

.portfolio-container__tag {
  font-family: $headerFont;
  text-transform: uppercase;
  margin: 0 0.5em 1.125rem 0;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0.9em;
  font-size: 0.7rem;
  border: 3px solid $ctaColor;
  color: $ctaColor;
  border-radius: 40px;

  @media (min-width: 700px) {
    font-size: 1em;
  }

  @media (min-width: 1160px) {
    margin-bottom: 1.5em;
  }
}

.btn {
  font-family: $headerFont;
  padding: 1em 2em;
  display: inline-block;
  align-self: start;
  position: relative;
  margin-bottom: 2em;
  color: $backgroundDefault;
  background-color: $ctaColor;

  @media (min-width: 900px) {
    font-size: 1.3em;
  }

  @media (min-width: 1160px) {
    font-size: 1.4em;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    border: 4px solid #1b1b1b;
    background-color: $backgroundDefault;
    z-index: -1;
  }

  &:hover {
    top: 0.25em;
    left: 0.25em;
    transition: position 0.5s ease-out;
  }

  &:hover::after {
    top: 0.25em;
    left: 0.25em;
  }
}

.btn-round {
  font-family: $headerFont;
  font-size: 1.5em;
  padding: .8em 2em;
  display: inline-block;
  align-self: start;
  position: relative;
  color: $ctaColor;
  border-radius: 2em;
  text-decoration: none;
  border: 5px solid $ctaColor;
  display: inline-block;

}

// Portfolio Home Page Images

.portfolio-container__image-wrapper {
  z-index: 10;
  flex: 7;
  width: 100%;
}

.portfolio-container__image {
  width: 100%;
  position: relative;
  // left: -12%;

  @media (min-width: 900px) {
    width: 100%;
  }

  @media (min-width: 1010px) {
    align-self: center;
    min-height: 200px;
  }

  // For portrait images
  &--portrait {
    max-width: 100%;

    @media (min-width: 500px) {
      width: 68%;
    }

    @media (min-width: 700px) {
      width: 50%;
    }

    @media (min-width: 900px) {
      width: 60%;
    }
  }
}

// Project Specific Styling

// Lantern
.portfolio-container--lantern {

  .portfolio-container__image-wrapper {
    padding-top: 2em;
    margin: 0 5em 0 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 5;

    @media (min-width: 900px) {
      padding-top: 0;
    }

    img {
      position: relative;

      @media (min-width: 900px) {
        left: 2em;
      }
    }
  }

  // .portfolio-container__image-wrapper::after {
  //   content: "1";
  //   font-size: 100vw;
  //   top: -30%;
  //   right: -10%;
  //   z-index: -1;
  //   color: $backgroundDefault;
  //   text-shadow: -5px -5px 0 $lanternDark, 5px -5px 0 $lanternDark, -5px 5px 0 $lanternDark, 5px 5px 0 $lanternDark;
  //   font-family: $headerFont;
  //   position: absolute;

  //   @media (min-width: 400px) {
  //     top: -45%;
  //     right: -5%;
  //   }
  //   @media (min-width: 550px) {
  //     top: -50%;
  //   }
  //   @media (min-width: 650px) {
  //     top: -55%;
  //   }
  //   @media (min-width: 900px) {
  //     font-size: 70vw;
  //     top: -42%;
  //     right: -12%;
  //   }
  //   @media (min-width: 950px) {
  //     font-size: 70vw;
  //     top: -50%;
  //     right: -12%;
  //   }
  //   @media (min-width: 1000px) {
  //     // font-size: 60vw;
  //     // top: -38%;
  //     right: -13%;
  //   }
  //   @media (min-width: 1050px) {
  //     top: -65%;
  //     // font-size: 50vw;
  //     right: -13%;
  //   }
  //   @media (min-width: 1200px) {
  //     // top: -50%;
  //   }
  //   @media (min-width: 1255px) {
  //     font-size: 60vw;
  //   }
  // }
}

// StreamLoan
.portfolio-container--streamloan {
  @media (min-width: 900px) {
    flex-direction: row-reverse;
  }

  .portfolio-container__info {
    justify-content: center;
    flex: 5;
    position: relative;
  }

  .portfolio-container__image-wrapper {
    flex: 5;
    padding-top: 3em;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;

    @media (min-width: 900px) {
      padding-top: 0;
    }
  }

  // .portfolio-container__image--portrait {
  //   width: 65%;

  //   @media (max-width: 900px) {
  //     width: 50%;
  //   }
  // }

  // .portfolio-container__image-wrapper::after {
  //   content: "2";
  //   font-size: 110vw;
  //   top: -10%;
  //   right: -5%;
  //   z-index: -1;
  //   font-family: $headerFont;
  //   position: absolute;
  //   color: $backgroundDefault;
  //   text-shadow: -8px 0 $streamloanDark, 0 8px $streamloanDark, 8px 0 $streamloanDark, 0 -8px $streamloanDark;

  //   @media (min-width: 400px) {
  //     top: -15%;
  //     // right: -5%;
  //   }
  //   @media (min-width: 900px) {
  //     font-size: 70vw;
  //     top: -35%;
  //     left: 10%;
  //   }
  //   @media (min-width: 1000px) {
  //     top: -40%;
  //   }
  //   @media (min-width: 1050px) {
  //     top: -45%;
  //   }
  //   @media (min-width: 1200px) {
  //     top: -40%;
  //     left: 13%;
  //   }
  // }

  .portfolio-container__counter {
    position: relative;
  }

  .portfolio-container__counter::after {
    // content: "2";
    font-size: 25em;
    top: -300px;
    right: 50%;
    left: 50%;
    bottom: 0;
    z-index: -1;
    color: $streamloanLight;
    font-family: $headerFont;
    position: absolute;
  }
}

// Amazon
.portfolio-container--amazon {
  padding-bottom: 8em;

  .portfolio-container__image-wrapper {
    position: relative;
    margin-top: 1em;
    padding-top: 1em;
  }

  @media (min-width: 900px) {
    flex-direction: row-reverse;

    .portfolio-container__info {
      margin-left: 6em;
    }
  }

  // .portfolio-container__image-wrapper::after {
  //   content: "3";
  //   font-size: 90vw;
  //   top: -40%;
  //   right: -10%;
  //   z-index: -1;
  //   color: $backgroundDefault;
  //   text-shadow: -8px 0 $amazonDark, 0 8px $amazonDark, 8px 0 $amazonDark, 0 -8px $amazonDark;

  //   font-family: $headerFont;
  //   position: absolute;

  //   @media (min-width: 425px) {
  //     font-size: 80vw;
  //   }
  //   @media (min-width: 700px) {
  //     font-size: 75vw;
  //   }
  //   @media (min-width: 900px) {
  //     font-size: 65vw;
  //   }
  //   @media (min-width: 1100px) {
  //     font-size: 60vw;
  //   }
  //   @media (min-width: 1250px) {
  //     font-size: 53vw;
  //   }
  // }
}

// Making Better Things Container //

.making-better__container {
  display: flex;
  justify-content: center;

  img {
  z-index: 1;
  padding-bottom: 1.5em;
}

}

.making-better__container--image {
  position: relative;
  top: 100px;

  @media (max-width: 1500px) {
    position: relative;
    top: 60px;
    padding-bottom: 2em;
    max-width:50%;
  }

}

// Extra Curricular Section //

.extra-container {
  display: grid;
  gap: 2em;
  grid-template-columns: [page-start] 1fr [content-start] 40vw [content-middle] 40vw [content-end] 1fr [page-end];
  // background: linear-gradient(180deg, #EADAFF 0%, #FCECF1 100%);
  background-image: url("/assets/images/lavendargradient.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 2000px) {
    h2 {
      font-size: 2.5em;
    }
  }
  @media (max-width: 1300px) {
    h2 {
      font-size: 2em;
    }
  }
}

.extra-container__title {
  padding: 0.5em 0 0.5em 0;
  grid-column: content-start / content-end;
}

.extra-container__section {
  // grid-column: content-start / content-end;
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  margin: 2em 0 5em 0;
  align-content: center;

    img {
      max-width: 100%;
    }
}

.hide-small {
  @media (max-width: 900px) {
    display: none;
  }
}

.extra-container__section--one {
  grid-column: content-start / content-middle;

  @media screen and (max-width: 900px){
    grid-column: content-start /content-end;
    grid-row: 2/3;
  }
}

.extra-container__section--one img {
  padding-bottom: 1em;

  @media screen and (max-width: 900px){
    max-width: 100%;
  }
}

.extra-container__section--two img {
  padding-bottom: 1em;

  @media screen and (max-width: 900px){
    max-width: 100%;
  }
}

.extra-container__section--two {
  grid-column: content-middle / content-end;

  @media screen and (max-width: 900px){
    grid-column: content-start /content-end;
    grid-row: 3/4;
  }
}

.extra-container__section--subheader {
  font-family: $subheaderFont;
  font-size: 1.8em;
  padding-bottom: 0.5em;
}

.extra-container__section--description {
  font-size: 1.5em;
  font-family: $subheaderFont;
  color: $bodyLight;
  margin: 1.125em, 0, 2em, 0;
}

// 

.hvr-pulse {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-pulse:hover,
.hvr-pulse:focus,
.hvr-pulse:active {
  -webkit-animation-name: hvr-pulse;
  animation-name: hvr-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

// About Section
.about-section {
  display: flex;
  padding: 2em 0;
  flex-direction: column;
  justify-content: center;
  display: grid;
  grid-template-columns: [about-start] 1fr [content-start] 40vw [center] 40vw [content-end] 1fr [about-end];
}

.about-section__header {
  color: $bodyColor;
  grid-column: content-start / content-end;
  font-size: 3em;
  padding-bottom: 0.5em;
}

.about-section__content {
  grid-column: content-start / content-end;
  grid-row: auto;
}

.about-section__image {
  max-width: 100%;
  grid-column: content-start / content-end;
  height: auto;

  &--image-shadow {
    box-shadow: 0px 4px 30px $imageShadow;
  }
}

@media (min-width: 900px) {
  .about-section {
    flex-direction: row;
    padding: 4em 0;
  }

  .about-section__header {
    grid-column: content-start / center;
  }

  .about-section__content {
    width: 84%;
    grid-column: content-start / center;
    grid-row: auto;
  }

  .about-section__image {
    max-width: 80%;
    grid-column: center / content-end;
    height: auto;
    // margin: 3em 0 0 0;
  }
  
}
